@import '@launchpad-ui/tokens/dist/fonts.css';
@import '@launchpad-ui/tokens/dist/index.css';
@import '@launchpad-ui/tokens/dist/media-queries.css';
@import '@launchpad-ui/tokens/dist/themes.css';

.root-layout {
  height: 100vh;
}

html {
  --font-family: 'Inter', sans-serif !important;
}

body,
html,
.ql-container {
  margin: 0;
  font-size: 14px;
  font-family: var(--font-family) !important;
  @supports (font-variation-settings: normal) {
    html {
      font-family: var(--font-family) !important;
    }
  }
}

.signin-with-google-button {
  background-color: #bae7ff !important;
  border-radius: 4px !important;
  color: black !important;
  padding: 0px 30px 0px 0px !important;
}

.ant-anchor::before {
  display: none;
}

.ant-anchor-link,
.ant-anchor-link-title {
  padding: 0 !important;
  color: var(--primary-100) !important;
}

.ant-card-actions {
  background: transparent;
}

/* Hide the scrollbar for all elements */
*::-webkit-scrollbar {
  display: none !important;
}

/* Style the scrollbar for the * element */
*::-webkit-scrollbar {
  display: block !important; /* Enable the scrollbar for the * element */
  width: 6px !important; /* Set the width of the scrollbar track */
  height: 6px !important; /* Set the height of the scrollbar thumb */
  background-color: transparent !important; /* Set the background color of the scrollbar track */
  border-radius: 4px !important; /* Set the border radius of the scrollbar thumb */
  cursor: pointer !important; /* Change the cursor on hover */
}

/* Style the scrollbar thumb */
*::-webkit-scrollbar-thumb {
  background-color: var(
    --border
  ) !important; /* Set the background color of the scrollbar thumb */
  border-radius: 4px !important; /* Set the border radius of the scrollbar thumb */
  cursor: pointer !important; /* Change the cursor on hover */
}

/* Change the scrollbar thumb color on hover */
*::-webkit-scrollbar-thumb:hover {
  background-color: var(--border) !important;
  cursor: pointer !important; /* Set the background color of the scrollbar thumb on hover */
}

.ant-select-selection-search-input {
  font-family: var(--font-family) !important;
}

.ant-form-item-extra {
  font-size: 0.9rem !important;
}

div.ant-typography-edit-content {
  left: unset;
  margin-top: unset;
  margin-bottom: unset;
}

div.ant-typography-edit-content > textarea {
  font-weight: 500;
}

.ag-grid-custom-cell-span,
.ant-input-search {
  background: var(--background);
}

.ant-radio-wrapper {
  align-items: center;
}
.ant-radio {
  top: unset;
}

.commandbar-launcher {
  display: none !important;
  left: 5px !important;
  bottom: 40px !important;
  right: unset !important;
}

//react-quil heading custom css override for Text block
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: flex;
  align-items: baseline;
  column-gap: 2rem;
  padding: 0 1rem;
  margin-top: 5px;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.hideScrollBar {
  -ms-overflow-style: none;
}

.block-rearrange-line {
  border-bottom: 2px solid var(--primary-100) !important;
}

// for error block
.error-block .ant-result {
  padding: 0;
}

.error-block .ant-result-icon {
  margin-bottom: 8px;
}

.error-block .ant-result-icon > .anticon {
  font-size: 24px;
}

.error-block .ant-result-title {
  font-size: 20px;
}

.breadcrumbItem,
.breadcrumbItemCurrent {
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer !important;
  font-size: 0.95rem;
}

.breadcrumbItemCurrent {
  color: var(--primary-100);
  font-weight: 550;
}

// Custom ClassName for Tabs, Enables the <Tabs/> component to take up the entire width of the parent and ensures that all the tab items in it are equally sized and take up the entire space available
.full-width-tabs .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.full-width-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  flex: 1;
  display: flex;
  justify-content: center;
}

// Custom ClassName for Segmented, Enables the <Segmented/> component to take up the entire width of the parent and ensures that all the tab items in it are equally sized and take up the entire space available
.full-width-segmented .ant-segmented-item {
  width: 100%;
}
.full-width-segmented {
  width: 100%;
}

// Custom classname for <Tabs /> which allows the the content to take up the entire height of the parent and ensures that the content is not scrollable
.scrollable-tabs-container .ant-tabs,
.scrollable-tabs-container .ant-tabs-content-holder,
.scrollable-tabs-container .ant-tabs-content,
.scrollable-tabs-container .ant-tabs-tabpane {
  height: 100% !important;
  overflow-y: hidden;
}

// Custom classname for <Card /> where we have certain actions which we want to show only on hover
.hoverable-card-with-hidden-actions {
  position: relative;

  .card-actions {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    .card-actions {
      visibility: visible;
      opacity: 1;
    }
  }
}
